<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box" :style="`background-color: ${'#F5B225'}`">
                <div class="fx-row fx-between fx-center">
                    <span class="order_detail-status">{{order.orderStatusText}}</span>
                    <div></div>
                    <!-- <van-icon name="delete-o" size="20" @click="confirmDelete"></van-icon> -->
                </div>
                <div class="order_detail-content--wrap">
                    <div class="fx-row fx-between">
                        <div>
                            <img :src="order.logo" style="width: 120px;height: 100px; border-radius: 4px;" />
                        </div>
                        <div class="order_detail-info fx-fill fx-column">
                            <div class="name">{{ order.cinemaBmhBatchName }} × {{ order.qty }}</div>
                            <div class="desc">{{ order.itemDesc }}</div>
                            <div class="price">单价：￥{{ order.price }}</div>
                        </div>
                    </div>
                    <div v-if="order.orderstatus != 'R'">
                        <div style="padding-top: 15px;">
                            <ticket-code-swiper :list="images" :loading="order.orderStatus == 'T'" width="240"></ticket-code-swiper>
                        </div>
                    </div>
                    <div class="fx-around" v-if="order.refundReason">
                        <div class="order_detail-name">退款原因</div>
                        <div class="fc-active">{{order.refundReason}}</div>
                    </div>
                    <div class="fx-around">
                        <div class="order_detail-name">{{order.cinemaName}}</div>
                        <div class="fc-tips">{{order.cinemaAddress}}</div>
                    </div>
                    <div class="fx-around">
                        <span style="padding-bottom: 5px;">
                            <span class="order_detail-name">实付金额：￥{{order.amount}} {{order.orderStatus == 'R' ? '(已退款)' : ''}} </span>
                            <span class="fs-9 fc-status">{{order.discount ? `(已优惠${order.discount})` : ''}}</span>
                        </span>
                        <span class="fc-tips" style="padding-bottom: 5px;">订单号： {{order.id}}</span>
                        <span class="fc-tips" style="padding-bottom: 5px;">下单时间： {{order.addTime}}</span>
                        <span class="fc-tips" style="padding-bottom: 5px;">手机号： {{order.mobile | hideMobile}}</span>
                    </div>
                </div>
                <div class="fx-center">
                    <van-button :icon="require('@/assets/images/user/user_customer_service.png')" round @click="toCustomer">联系客服</van-button>
                </div>
            </div>
        </div>
        <div class="util-wrap">
            <van-image-preview v-model="show" :images="images" :start-position="previewIndex">
            </van-image-preview>
        </div>
    </div>
</template>

<script>
import { getFoodOrder } from '@api/order-request'
import QrCode from '@/components/qr-code'
import ImageList from '@/components/image-list';
import TicketCodeSwiper from '@/components/ticket-code-swiper';
export default {
    data() {
        return {
            orderId: this.$route.query.orderId,
            order: {},
            activeTicket: 0,
            show: false,
            previewIndex: 0,
        }
    },
    computed: {
        chupiaoTime() {
            return this.$store.getters.baseData.chupiaoTime;
        },
        serviceTel() {
            return this.$store.getters.baseData.serviceTel;
        },
        images() {
            let images = [];
            if (this.order?.couponList && this.order?.couponList.length > 0) {
                images = this.order.couponList.map((item) => {
                    return {
                        image: item.couponPassPicUrl,
                        mobile: item.mobile,
                        text: item.validPass
                    };
                })
            }
            return images;
        }
    },
    methods: {
        getOrderDetail() {
            getFoodOrder(this.orderId).then((res) => {
                this.order = res;
                // this.order.couponList = this.order.couponList.concat(this.order.couponList)
            })
        },
        toCustomer() {
            window.location.href = `tel: ${this.serviceTel}`;
        },
        confirmDelete() {
            this.$dialog.confirm({
                title: '系统提示',
                message: '确认删除该订单记录吗?',
            }).then(() => {
                let loading = this.$toast.loading('删除中');
                deleteOrder(this.orderId).then(() => {
                    loading.clear();
                    setTimeout(() => {
                        this.$goPage(-1);
                    }, 0);
                }).catch(() => {
                    loading.clear();
                })
            }).catch(() => { });
        },
        goApplyRefund() {
            this.$goPage('request-refund', {
                orderId: this.orderId
            })
        }
    },
    created() {
        this.getOrderDetail();
    },
    components: {
        QrCode,
        TicketCodeSwiper,
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	> div {
		padding: 10px;
		> div:nth-of-type(1) {
			color: #fff;
			padding: 8px 0;
			.order_detail-status {
				font-size: 16px;
			}
		}
		.order_detail-content--wrap {
			background-color: #fff;
			border-radius: 4px;
			margin: 15px 0;
			padding: 0 20px;
			> div {
				border-bottom: 2px dashed $border-color;
				padding: 16px 0;
				.order_detail-ticket {
					border: 1px solid #dededf;
					color: $second-color;
					width: 88%;
					padding: 10px;
					font-size: 14px;
					border-radius: 4px;
					margin: 4px;
				}

				.order_detail-info {
					margin-left: 10px;

					> div {
						margin-bottom: 8px;
					}
					.name {
						font-size: 16px;
					}

					.desc {
						color: $tips-color;
					}

                    .price {
                        color: $tips-color;
                    }
				}
			}
			.order_detail-name {
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 10px;
			}
		}
	}
}

.refund {
	color: $tips-color;
	text-decoration: underline;
}
</style>
